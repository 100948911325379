// CookiesConsent.js

import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';

const CookiesConsent = () => {
    const [accepted, setAccepted] = useState(localStorage.getItem('cookiesAccepted') === 'true');
    const [showModal, setShowModal] = useState(false);
    const [cookiePreferences, setCookiePreferences] = useState({
        essential: true,
        analytics: false,
        marketing: false
    });

    useEffect(() => {
        const savedPreferences = JSON.parse(localStorage.getItem('cookiePreferences'));
        if (savedPreferences) {
            setCookiePreferences(savedPreferences);
        }
    }, []);

    const acceptCookies = () => {
        setAccepted(true);
        localStorage.setItem('cookiesAccepted', true);
    };

    const openModifyModal = () => {
        setShowModal(true);
    };

    const closeModifyModal = () => {
        setShowModal(false);
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        localStorage.setItem('cookiePreferences', JSON.stringify(cookiePreferences));
        localStorage.setItem('cookiesAccepted', true); // Marcamos las cookies como aceptadas al guardar las preferencias
        setAccepted(true); // Actualizamos el estado local
        closeModifyModal();
    };

    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
        setCookiePreferences({
            ...cookiePreferences,
            [id]: checked
        });
    };

    if (accepted) {
        return null; // Si las cookies ya han sido aceptadas, no mostramos el aviso
    }

    return (
        <div className="cookies-consent" style={{ backgroundColor: '#333', color: '#fff', padding: '10px', position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 9999 }}>
            <p>Este sitio web utiliza cookies para mejorar la experiencia del usuario. Utilizamos cookies esenciales y tecnologías similares para asegurarnos de que nuestro sitio web funcione correctamente.</p>
            <div className="cookies-buttons">
                <button className="btn btn-primary" onClick={acceptCookies}>Aceptar cookies</button>
                <button className="btn btn-outline-primary" onClick={openModifyModal} style={{marginLeft: '10px'}}>Modificar preferencias</button>
            </div>

            <Modal show={showModal} onHide={closeModifyModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Preferencias de Cookies</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Configura tus preferencias de cookies aquí:</p>
                    <Form onSubmit={handleFormSubmit}>
                        <Form.Group controlId="essential">
                            <Form.Check
                                type="checkbox"
                                label="Cookies esenciales (necesarias para el funcionamiento del sitio)"
                                checked={cookiePreferences.essential}
                                disabled
                            />
                        </Form.Group>
                        <Form.Group controlId="analytics">
                            <Form.Check
                                type="checkbox"
                                label="Cookies de análisis (para analizar y mejorar el rendimiento del sitio)"
                                checked={cookiePreferences.analytics}
                                onChange={handleCheckboxChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="marketing">
                            <Form.Check
                                type="checkbox"
                                label="Cookies de marketing (para mostrar contenido relevante y anuncios personalizados)"
                                checked={cookiePreferences.marketing}
                                onChange={handleCheckboxChange}
                            />
                        </Form.Group>
                        {/* Aquí puedes añadir más opciones de cookies según las necesidades de tu sitio */}
                        <Button className="btn btn-primary" type="submit">
                            Guardar Preferencias
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModifyModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CookiesConsent;
