import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import landing_classes from '../css/landing.module.css';

const ReservasModal = ({ show, onHide }) => {
    const initialFormData = {
        fechaInicio: '',
        horaInicio: '',
        fechaFin: '',
        horaFin: '',
        disponibilidad: null,
        reservaRealizada: false,
        nombreCliente: '',
        emailCliente: '',
        telefonoCliente: '',
        multipleDias: false,
        codigoReservaBusqueda: '',
        codigoReserva: '',
    };

    const [formData, setFormData] = useState({ ...initialFormData });
    const [error, setError] = useState('');
    const [showReservaForm, setShowReservaForm] = useState(false);
    const [showBuscarReservaForm, setShowBuscarReservaForm] = useState(false);
    const [reservaEncontrada, setReservaEncontrada] = useState(null);

    const {
        fechaInicio,
        horaInicio,
        fechaFin,
        horaFin,
        disponibilidad,
        reservaRealizada,
        nombreCliente,
        emailCliente,
        telefonoCliente,
        multipleDias,
        codigoReservaBusqueda,
        codigoReserva,
    } = formData;

    const toggleModal = () => {
        setFormData({ ...initialFormData });
        setShowReservaForm(false);
        setShowBuscarReservaForm(false);
        setError('');
        setReservaEncontrada(null);
        onHide();
    };

    const comprobarDisponibilidad = async () => {
        if (!fechaInicio || !horaInicio || (multipleDias && (!fechaFin || !horaFin))) {
            setError('Por favor, introduzca todas las fechas y horas requeridas.');
            return;
        }

        if (new Date(`${fechaInicio}T${horaInicio}`) > new Date(`${fechaFin}T${horaFin}`)) {
            setError('La fecha y hora de inicio no pueden ser posteriores a la fecha y hora de fin.');
            return;
        }

        setError('');

        try {
            const response = await axios.post('http://localhost:3001/check_availability', {
                fecha_inicio: fechaInicio,
                hora_inicio: horaInicio,
                fecha_fin: multipleDias ? fechaFin : fechaInicio,
                hora_fin: multipleDias ? horaFin : horaInicio,
            });
            const available = response.data.available;
            setFormData({ ...formData, disponibilidad: available });
            if (available) {
                setShowReservaForm(true);
            } else {
                setShowReservaForm(true);
                return;
            }
        } catch (error) {
            console.error('Error comprobando disponibilidad', error);
        }
    };

    const validarCampos = () => {
        if (!nombreCliente || !emailCliente || !telefonoCliente) {
            setError('Por favor, complete todos los campos.');
            return false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(emailCliente)) {
            setError('Por favor, introduzca un correo electrónico válido.');
            return false;
        }

        const telefonoRegex = /^\d{9}$/; // Ajustar según el formato de teléfono deseado
        if (!telefonoRegex.test(telefonoCliente)) {
            setError('Por favor, introduzca un número de teléfono válido.');
            return false;
        }

        setError('');
        return true;
    };

    const hacerReserva = async () => {
        if (!validarCampos()) {
            return;
        }

        try {
            const response = await axios.post('http://localhost:3001/make_reservation', {
                fecha_inicio: fechaInicio,
                hora_inicio: horaInicio,
                fecha_fin: multipleDias ? fechaFin : fechaInicio,
                hora_fin: multipleDias ? horaFin : horaInicio,
                nombre_cliente: nombreCliente,
                email_cliente: emailCliente,
                telefono_cliente: telefonoCliente,
            });
            if (response.data.success) {
                setFormData({
                    ...initialFormData,
                    reservaRealizada: true,
                    codigoReserva: response.data.codigoReserva,
                });
            } else {
                console.error('Error al intentar realizar la reserva');
            }
        } catch (error) {
            console.error('Error realizando reserva', error);
        }
    };

    const buscarReserva = async () => {
        setError('');
        console.log('Enviando solicitud para buscar reserva con código:', codigoReservaBusqueda);
        try {
            const response = await axios.post('http://localhost:3001/buscar_reserva', {
                codigoReserva: codigoReservaBusqueda,
            });
            console.log('Respuesta del servidor:', response.data);
            if (response.data.success && response.data.reservaEncontrada) {
                const reservaEncontrada = response.data.reservaEncontrada;
                // Formatear las fechas
                reservaEncontrada.fecha_inicio = formatFecha(reservaEncontrada.fecha_inicio);
                reservaEncontrada.fecha_fin = formatFecha(reservaEncontrada.fecha_fin);
                reservaEncontrada.created_at = formatFecha(reservaEncontrada.created_at);
                setReservaEncontrada(reservaEncontrada);
            } else {
                setReservaEncontrada(null);
                setError('No se encontró ninguna reserva con ese código.');
            }
        } catch (error) {
            console.error('Error buscando reserva', error);
            setError('Error al buscar la reserva. Por favor, inténtelo de nuevo más tarde.');
        }
    };

    const formatFecha = (fecha) => {
        const dateObj = new Date(fecha);
        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        return dateObj.toLocaleDateString('es-ES', options);
    };

    const handleReservaClick = () => {
        setShowReservaForm(true);
        setShowBuscarReservaForm(false);
        setFormData({
            ...formData,
            disponibilidad: null,
        });
    };

    const handleBuscarReservaClick = () => {
        setShowBuscarReservaForm(true);
        setShowReservaForm(false);
        setFormData({
            ...formData,
            reservaRealizada: false,
            codigoReservaBusqueda: '',
        });
        setReservaEncontrada(null);
        setError('');
    };

    return (
        <Modal show={show} onHide={toggleModal} centered>
            <Modal.Header closeButton>
                <Modal.Title className={landing_classes.formTitle}>Reservas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!showReservaForm && !showBuscarReservaForm && (
                    <>
                    <div style={{display: 'flex', aligItems: 'center', justifyContent: 'space-around'}}>
                        <Button variant="primary" className={landing_classes.button} onClick={handleReservaClick}>
                            Realizar una Reserva
                        </Button>
                        <Button variant="secondary" className={landing_classes.button} onClick={handleBuscarReservaClick}>
                            Buscar una Reserva
                        </Button>
                        </div>
                    </>
                )}
                {showReservaForm && (
                    <Form>
                        <Form.Group controlId="fechaInicio">
                            <Form.Label>Fecha de inicio:</Form.Label>
                            <Form.Control
                                type="date"
                                value={fechaInicio}
                                onChange={(e) => setFormData({ ...formData, fechaInicio: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="horaInicio">
                            <Form.Label>Hora de inicio:</Form.Label>
                            <Form.Control
                                type="time"
                                value={horaInicio}
                                onChange={(e) => setFormData({ ...formData, horaInicio: e.target.value })}
                            />
                        </Form.Group>
                        {multipleDias && (
                            <>
                                <Form.Group controlId="fechaFin">
                                    <Form.Label>Fecha de fin:</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={fechaFin}
                                        onChange={(e) => setFormData({ ...formData, fechaFin: e.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group controlId="horaFin">
                                    <Form.Label>Hora de fin:</Form.Label>
                                    <Form.Control
                                        type="time"
                                        value={horaFin}
                                        onChange={(e) => setFormData({ ...formData, horaFin: e.target.value })}
                                    />
                                </Form.Group>
                            </>
                        )}
                        <Form.Group controlId="multipleDias">
                            <Form.Check
                                type="checkbox"
                                label="Reserva para múltiples días"
                                checked={multipleDias}
                                onChange={(e) => setFormData({ ...formData, multipleDias: e.target.checked })}
                            />
                        </Form.Group>
                        <Button variant="primary" className={landing_classes.button} onClick={comprobarDisponibilidad}>
                            Comprobar disponibilidad
                        </Button>
                        {disponibilidad !== null && (
                            <div className={`${landing_classes.alertMessage} ${disponibilidad ? landing_classes.alertSuccess : landing_classes.alertError}`}>
                                {disponibilidad ? (
                                    <>
                                        <p>La fecha y hora seleccionada están disponibles.</p>
                                        <Form.Group controlId="nombreCliente">
                                            <Form.Label>Nombre del Cliente:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={nombreCliente}
                                                onChange={(e) => setFormData({ ...formData, nombreCliente: e.target.value })}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="emailCliente">
                                            <Form.Label>Email del Cliente:</Form.Label>
                                            <Form.Control
                                                type="email"
                                                value={emailCliente}
                                                onChange={(e) => setFormData({ ...formData, emailCliente: e.target.value })}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="telefonoCliente">
                                            <Form.Label>Teléfono del Cliente:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={telefonoCliente}
                                                onChange={(e) => setFormData({ ...formData, telefonoCliente: e.target.value })}
                                            />
                                        </Form.Group>
                                        <Button variant="primary" style={{marginTop: '10px'}} className={landing_classes.button} onClick={hacerReserva}>
                                            Realizar Reserva
                                        </Button>
                                    </>
                                ) : (
                                    <p>Fecha no disponible, por favor modifica la fecha para realizar otra búsqueda.</p>
                                )}
                            </div>
                        )}
                        {reservaRealizada && (
                            <div className={`${landing_classes.alertMessage} ${landing_classes.alertSuccess}`}>
                                <p>¡Reserva realizada con éxito! Su código de reserva es: {codigoReserva}</p>
                            </div>
                        )}
                        {error && <div className={`${landing_classes.alertMessage} ${landing_classes.alertError}`}><p>{error}</p></div>}
                    </Form>
                )}
                {showBuscarReservaForm && (
                    <Form>
                        <Form.Group controlId="codigoReservaBusqueda">
                            <Form.Label>Código de Reserva:</Form.Label>
                            <Form.Control
                                type="text" placeholder="ej: RE236RE4T"
                                value={codigoReservaBusqueda}
                                onChange={(e) => setFormData({ ...formData, codigoReservaBusqueda: e.target.value })}
                            />
                        </Form.Group>
                        <Button variant="primary" style={{marginTop: '10px'}} className={landing_classes.button} onClick={buscarReserva}>
                            Buscar Reserva
                        </Button>
                        {reservaEncontrada && (
                            <div className={`${landing_classes.alertMessage} ${landing_classes.alertSuccess}`}>
                                <h5>Reserva Encontrada:</h5>
                                <p><strong>Fecha de inicio:</strong> {reservaEncontrada.fecha_inicio}</p>
                                <p><strong>Fecha de fin:</strong> {reservaEncontrada.fecha_fin}</p>
                                <p><strong>Nombre del Cliente:</strong> {reservaEncontrada.nombre_cliente}</p>
                                <p><strong>Email del Cliente:</strong> {reservaEncontrada.email_cliente}</p>
                                <p><strong>Teléfono del Cliente:</strong> {reservaEncontrada.telefono_cliente}</p>
                                <p><strong>Fecha de creación:</strong> {reservaEncontrada.created_at}</p>
                            </div>
                        )}
                        {error && <div className={`${landing_classes.alertMessage} ${landing_classes.alertError}`}><p>{error}</p></div>}
                    </Form>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={toggleModal} className={landing_classes.button}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ReservasModal;
