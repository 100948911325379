// landing.jsx

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CSSTransitionGroup } from 'react-transition-group';
import landing_classes from '../css/landing.module.css';
import nav_classes from '../css/mainNavBar.module.css';
import { Social } from './social';
import ReservasModal from './ReservasModal'; // Importar el componente ReservasModal
import WhatsAppModal from './WhatsAppModal';

const Landing = () => {
    const [showReservasModal, setShowReservasModal] = useState(false);
    const [showWhatsAppModal, setShowWhatsAppModal] = useState(false);

    const toggleReservasModal = () => {
        setShowReservasModal(!showReservasModal);
    };
    const handleWhatsAppClick = () => {
        setShowWhatsAppModal(true);
    };

    const handleCloseWhatsAppModal = () => {
        setShowWhatsAppModal(false);
    };

    return (
        <CSSTransitionGroup
            transitionName="transition"
            transitionAppear={true}
            transitionAppearTimeout={500}
            transitionEnterTimeout={500}
            transitionLeaveTimeout={300}
        >
            <div className={landing_classes.landingWrapper}>
                <div className={landing_classes.navBarWrapper}>
                    <div className={landing_classes.title}>
                        <img
                            className={landing_classes.imageLogo}
                            src={process.env.PUBLIC_URL + '/img/logos/logow.png'}
                            alt=""
                        />
                        <br />
                        <small className={landing_classes.smallSlogan}>
                            "DRK no tiene un estilo, DRK es un estilo."
                        </small>
                    </div>
                    <nav className={landing_classes.navBar}>
                        <ul>
                            <li>
                                <NavLink to="/sobremi" className={landing_classes.btn}>
                                    SOBRE MÍ
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/servicios" className={landing_classes.btn}>
                                    SERVICIOS
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/tarifas" className={landing_classes.btn}>
                                    TARIFAS
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/contacto" className={landing_classes.btn}>
                                    CONTACTO
                                </NavLink>
                            </li>
                            <li>
                                <button onClick={toggleReservasModal} className={landing_classes.btn}>
                                    RESERVAS
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className={landing_classes.socialWrapper}>
                    <Social />
                </div>

                {/* Modal de Reservas */}
                <ReservasModal show={showReservasModal} onHide={toggleReservasModal} />
                

            {/* Añadir el icono de WhatsApp */}
            
            </div>
            <WhatsAppModal show={showWhatsAppModal} onHide={handleCloseWhatsAppModal} />
            <span 
                onClick={handleWhatsAppClick} 
                className={nav_classes.whatsappLink}
            >
                <img src={process.env.PUBLIC_URL + '/img/logos/whatsapp.png'} alt="WhatsApp" className={nav_classes.whatsappIcon} />
            </span>
        </CSSTransitionGroup>
    );
};

export default Landing;
