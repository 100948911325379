// app.js

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Sobremi } from './components/jsx/sobremi';
import { BackgroundVideo } from './components/jsx/backgroundVideo';
import Landing from './components/jsx/landing'; // Importar Landing como un componente por defecto
import Tarifas from './components/jsx/tarifas';
import { Contacto } from './components/jsx/contacto';
import Producciones from './components/jsx/producciones';
import ReservasModal from './components/jsx/ReservasModal'; // Importar el componente del modal de reservas
import CookiesConsent from './CookiesConsent'; // Importar el componente de aviso de cookies
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showReservasModal: false // Estado para controlar la visibilidad del modal de reservas
        };
    }

    // Función para abrir el modal de reservas
    openReservasModal = () => {
        this.setState({ showReservasModal: true });
    };

    // Función para cerrar el modal de reservas
    closeReservasModal = () => {
        this.setState({ showReservasModal: false });
    };

    render() {
        return (
            <Router>
                <BackgroundVideo/>
                <CookiesConsent /> {/* Mostrar el aviso de cookies */}
                <Switch location={this.props.location}>
                    <Route exact path="/" component={Landing}/>
                    <Route exact path="/sobremi" component={Sobremi}/>
                    <Route exact path="/servicios" component={Producciones}/>
                    <Route exact path="/tarifas" component={Tarifas}/>
                    <Route exact path="/contacto" component={Contacto}/>
                </Switch>
                <ReservasModal show={this.state.showReservasModal} onHide={this.closeReservasModal}/>
            </Router>
        );
    }
}

export default App;
