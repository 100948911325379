import React, { Component } from 'react';
import face_classes from '../css/face.module.css';
import classes from '../css/producciones_galeria.module.css';
import ClipLoader from "react-spinners/ClipLoader";
import { MainNavBar } from "./mainNavBar";
import { Footer } from "./footer";

class ProduccionesGaleria extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showModal: false,
            showBrandGallery: false,
            currentVideoSrc: '',
            viewType: 'grid',
            filterType: 'fotografia',
            resources: {
                fotografia: [],
                producciones: [],
                marcas: [],
            },
            currentPage: {
                fotografia: 1,
                producciones: 1,
                marcas: 1,
            },
            itemsPerPage: 6,
            selectedBrand: null,
        };
    }

    componentDidMount() {
        this.loadResources();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.filterType !== this.state.filterType || prevState.currentPage[this.state.filterType] !== this.state.currentPage[this.state.filterType]) {
            this.loadResources();
        }
    }

    loadResources = () => {
        const { filterType, currentPage } = this.state;

        setTimeout(() => {
            if (filterType === 'fotografia') {
                const paths = this.getImagePaths('fotografia', currentPage.fotografia);
                this.setState({
                    resources: {
                        ...this.state.resources,
                        [filterType]: paths,
                    },
                    loading: false,
                });
            } else if (filterType === 'producciones') {
                const paths = this.getVideoPaths();
                this.setState({
                    resources: {
                        ...this.state.resources,
                        [filterType]: paths,
                    },
                    loading: false,
                });
            } else if (filterType === 'marcas') {
                const brandPaths = this.getBrandPaths();
                this.setState({
                    resources: {
                        ...this.state.resources,
                        [filterType]: brandPaths,
                    },
                    loading: false,
                });
            }
        }, 500);
    }

    preloadResource = (src) => {
        return new Promise((resolve, reject) => {
            if (src.endsWith('.mp4')) {
                const video = document.createElement('video');
                video.src = src;
                video.onloadeddata = () => resolve(src);
                video.onerror = reject;
            } else {
                const img = new Image();
                img.src = src;
                img.onload = () => resolve(src);
                img.onerror = reject;
            }
        });
    }

    getVideoPaths() {
        return [
            `${process.env.PUBLIC_URL}/video/producciones/video1.mp4`,
            `${process.env.PUBLIC_URL}/video/producciones/video2.mp4`,
            `${process.env.PUBLIC_URL}/video/producciones/video3.mp4`,
            `${process.env.PUBLIC_URL}/video/producciones/video4.mp4`,
            `${process.env.PUBLIC_URL}/video/producciones/video5.mp4`,
            `${process.env.PUBLIC_URL}/video/producciones/video6.mp4`,
            `${process.env.PUBLIC_URL}/video/producciones/video7.mp4`,
            `${process.env.PUBLIC_URL}/video/producciones/video8.mp4`,
            `${process.env.PUBLIC_URL}/video/producciones/video9.mp4`,
        ];
    }

    getImagePaths(category) {
        const start = category === 'fotografia' ? 1 : 7;
        const end = start + 39;
        return Array.from({ length: end - start + 1 }, (_, i) => `${process.env.PUBLIC_URL}/img/galeria/img${i + start}.jpeg`);
    }

    getBrandPaths() {
        const brandDir = `${process.env.PUBLIC_URL}/video/marcas`;

        const folderNames = ['Ziggy', 'NaturalBride', 'LeBriemme'];

        const brands = folderNames.map(folder => {
            const brandDirPath = `${brandDir}/${folder}`;
            const brandImages = this.getImagesForBrand(brandDirPath);
            const brandVideos = this.getVideosForBrand(brandDirPath);
            const brandImage = `${brandDirPath}/1.jpeg`;
            return { name: folder, image: brandImage, images: brandImages, videos: brandVideos };
        });

        return brands;
    }

    getImagesForBrand(brandDir) {
        const images = Array.from({ length: 7 }, (_, i) => `${brandDir}/${i + 1}.jpeg`);
        return images;
    }

    getVideosForBrand(brandDir) {
        const videos = Array.from({ length: 2 }, (_, i) => `${brandDir}/${i + 1}.mp4`);
        return videos;
    }

    openModal = (src) => {
        this.setState({ showModal: true, currentVideoSrc: src });
    }

    closeModal = () => {
        this.setState({ showModal: false, currentVideoSrc: '' });
    }

    closeBrandGallery = () => {
        this.setState({ showBrandGallery: false, selectedBrand: null });
    }

    setFilter = (filterType) => {
        this.setState({ filterType, currentPage: { ...this.state.currentPage, [filterType]: 1 } });
    }

    handlePageChange = (category, pageNumber) => {
        this.setState({ currentPage: { ...this.state.currentPage, [category]: pageNumber } });
    }

    openBrandGallery = (brand) => {
        this.setState({ showBrandGallery: true, selectedBrand: brand });
    }

    renderContent() {
        const { filterType, viewType, resources, currentPage, itemsPerPage, showBrandGallery, selectedBrand } = this.state;
        const currentItems = resources[filterType];
        const indexOfFirstItem = (currentPage[filterType] - 1) * itemsPerPage;
        const indexOfLastItem = indexOfFirstItem + itemsPerPage;

        let content = [];

        if (filterType === 'fotografia') {
            content = currentItems
                .slice(indexOfFirstItem, indexOfLastItem)
                .map((src, index) => (
                    <img
                        key={indexOfFirstItem + index}
                        src={src}
                        alt={`Fotografía ${indexOfFirstItem + index}`}  // Ejemplo de descripción más específica
                        className={`${classes.studiosImg} ${classes.studiosImgCover}`}
                        onClick={() => this.openModal(src)}
                    />


                ));
        } else if (filterType === 'producciones') {
            content = currentItems
                .slice(indexOfFirstItem, indexOfLastItem)
                .map((src, index) => (
                    <div key={indexOfFirstItem + index} className={classes.videoThumbnail} onClick={() => this.openModal(src)}>
                        <video className={classes.studiosVideoThumbnail} src={src} muted />
                    </div>
                ));
        } else if (filterType === 'marcas') {
            content = currentItems.map((brand, index) => (
                <div key={index} className={classes.brandThumbnail} onClick={() => this.openBrandGallery(brand)} style={{ backgroundImage: `url(${brand.image})` }}>
                    <div className={classes.brandOverlay}>
                        <p className={classes.brandName}>{brand.name}</p>
                    </div>
                </div>
            ));
        }

        return (
            <div className={viewType === 'grid' ? classes.workWrapper : classes.listWrapper}>
                {showBrandGallery ? (
                    <div className={classes.studiosWrapper}>
                        <button className={classes.backButton} onClick={this.closeBrandGallery}>Volver Atrás</button>
                        <div className={classes.workWrapper} style={{marginTop: '10px'}}>
                            {selectedBrand.images.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`Imagen de marca ${index}`}  // Ejemplo de descripción más específica
                                    className={`${classes.studiosImg} ${classes.studiosImgCover}`}
                                    onClick={() => this.openModal(image)}
                                />
                            
                            ))}
                            {selectedBrand.videos.map((video, index) => (
                                <div key={index} className={classes.studiosImgCover} onClick={() => this.openModal(video)}>
                                    <video src={video} muted className={classes.videoThumbnail} />
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    content
                )}
            </div>
        );
    }

    renderPaginationControls() {
        const { resources, filterType, currentPage, itemsPerPage } = this.state;
        const pageNumbers = Math.ceil(resources[filterType].length / itemsPerPage);

        return (
            <div className={`${classes.paginationWrapper}`}>
                <div className={`${classes.pagination}`}>
                    {Array.from({ length: pageNumbers }, (_, i) => (
                        <button
                            key={i + 1}
                            className={`${classes.paginationButton} ${currentPage[filterType] === i + 1 ? classes.active : ''}`}
                            onClick={() => this.handlePageChange(filterType, i + 1)}
                        >
                            {i + 1}
                        </button>
                    ))}
                </div>
            </div>
        );
    }

    render() {
        const { loading, showModal, currentVideoSrc, filterType } = this.state;

        return (
            <div className={face_classes.faceWrapper}>
                <MainNavBar />
                <div>
                    <div className={classes.filterBar}>
                        <button
                            className={`${                        classes.filterButton} ${filterType === 'fotografia' ? classes.active : ''}`}
                            onClick={() => this.setFilter('fotografia')}
                        >
                            Fotografía
                        </button>
                        <button
                            className={`${classes.filterButton} ${filterType === 'producciones' ? classes.active : ''}`}
                            onClick={() => this.setFilter('producciones')}
                        >
                            Producciones
                        </button>
                        <button
                            className={`${classes.filterButton} ${filterType === 'marcas' ? classes.active : ''}`}
                            onClick={() => this.setFilter('marcas')}
                        >
                            Marcas
                        </button>
                    </div>

                    {/* Renderiza el contenido y los controles de paginación */}
                    {loading ? (
                        <div className={classes.loaderWrapper}>
                            <ClipLoader
                                css={{ textAlign: 'center', display: 'block', margin: 'auto', top: '50%' }}
                                size={200}
                                color={"#fff"}
                                loading={loading} />
                            <h2>Cargando contenido...</h2>
                        </div>
                    ) : (
                        <>
                            {this.renderContent()}
                            {this.renderPaginationControls()}
                        </>
                    )}

                    {/* Modal para mostrar imágenes o vídeos */}
                    {showModal && (
                        <div className={classes.modalBackdrop}>
                            <div className={classes.videoModal}>
                                <button className={classes.closeButton} onClick={this.closeModal}>X</button>
                                {currentVideoSrc.endsWith('.mp4') ? (
                                    <video
                                        className={classes.studiosVideo}
                                        src={currentVideoSrc}
                                        controls
                                        autoPlay
                                    />
                                ) : (
                                    <img
                                        className={classes.studiosImg2}
                                        src={currentVideoSrc}
                                        alt="modal-img"
                                    />
                                )}
                            </div>
                        </div>
                    )}

                </div>
                <Footer />
            </div>
        );
    }
}

export default ProduccionesGaleria;
