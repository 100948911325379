import React from 'react';
import face_classes from '../css/face.module.css';
import contact_classes from '../css/contacto.module.css';
import ClipLoader from "react-spinners/ClipLoader";
import {MainNavBar} from "./mainNavBar";
import {Footer} from "./footer";

export class Contacto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            formSubmitted: false
        };
    }

    componentDidMount() {
        // Simulación de carga de imágenes (3 segundos)
        setTimeout(() => {
            this.setState({ loading: false });
        }, 3000);
    }

    handleSubmit = (event) => {
        event.preventDefault();
        // Aquí puedes agregar la lógica para enviar el formulario
        // Por ejemplo, puedes enviar los datos del formulario a un servidor
        // y luego mostrar un mensaje de éxito o error al usuario.
        this.setState({ formSubmitted: true });
    };

    render() {
        return (
            <div className={face_classes.faceWrapper}>
                <MainNavBar/>
                <div>
                    {this.state.loading ? (
                        <div className={contact_classes.loaderWrapper}>
                            <ClipLoader
                                css={'text-align: center; display: block; margin: auto; top: 50%;'}
                                size={200}
                                color={"#fff"}
                                loading={this.state.loading}/>
                            <h2>Cargando...</h2>
                        </div>
                    ) : (
                        <div className={contact_classes.formWrapper}>
                            {this.state.formSubmitted ? (
                                <div className={`${contact_classes.successMessage} contact-form`}>
                                    <h2>¡Gracias por ponerte en contacto con nosotros!</h2>
                                    <p>Nos pondremos en contacto contigo lo antes posible.</p>
                                </div>
                            ) : (
                                <form className="contact-form" onSubmit={this.handleSubmit}>
                                    <small style={{fontSize: "1.2rem" , color: "white"}}>¿Listo para dar vida a tus proyectos? ¡No dudes en ponerte en contacto conmigo para discutir tus ideas y necesidades! Estoy aquí para ayudarte en todo lo relacionado con la fotografía, cinematografía y videografía. Puedes encontrarme y comunicarte conmigo a través de las siguientes vías.</small>
                                <h1 className={contact_classes.contacttitle}>Formulario de Contacto</h1>
                                <div className="form-group">
                                    <input type="text" placeholder="Nombre" required />
                                </div>
                                <div className="form-group">
                                    <input type="email" placeholder="Correo electrónico" required />
                                </div>
                                <div className="form-group">
                                    <textarea placeholder="Mensaje" rows="4" required style={{marginTop: '10px'}}></textarea>
                                </div>
                                <button type="submit" style={{marginTop: '10px'}}>Enviar mensaje</button>
                            </form>
                            

                            )}
                        </div>
                    )}
                </div>
                <Footer/>
            </div>
        );
    }
}
