import React from 'react';
import face_classes from '../css/face.module.css'
import about_classes from '../css/sobremi.module.css'
import {MainNavBar} from "./mainNavBar";
import {Footer} from "./footer";
import {CSSTransitionGroup} from "react-transition-group";

export const Sobremi = () => {
    return (
        <div className={face_classes.faceWrapper}>
            <MainNavBar/>
            <CSSTransitionGroup
                transitionName="transition"
                transitionAppear={true}
                transitionAppearTimeout={500}
                transitionEnterTimeout={500}
                transitionLeaveTimeout={300}>
                <div className={about_classes.aboutWrapper}>
                <span className={about_classes.aboutTextBase}>
                    INFORMACIÓN SOBRE DRK-CONCEPT<br/><br/>
                    <span className={about_classes.aboutTextHeavy}>Hola, soy Erlin Familia, aunque muchos me conocen como Drk Concept. Desde hace casi 4 años, me dedico a capturar y crear momentos únicos a través de la fotografía y el filmmaking. Mi pasión por contar historias visuales y mi compromiso con la excelencia se reflejan en cada uno de mis trabajos.</span><br/><br/>
                    <span className={about_classes.aboutTextHeavy}>En esta página, podrás explorar una selección de mis proyectos más destacados en ambos ámbitos. Cada imagen y cada video aquí presente es el resultado de mi dedicación y amor por lo que hago.</span><br/><br/>
                    <span className={about_classes.aboutTextHeavy}>Me encanta conectar con las personas y crear un ambiente de confianza y colaboración. Si tienes una idea en mente o un proyecto que te gustaría realizar, no dudes en ponerte en contacto conmigo. Juntos podemos crear algo realmente especial.
                    Gracias por visitar mi página. Espero que disfrutes de mi trabajo tanto como yo disfruto creándolo.</span><br/><br/>
                     <span className={about_classes.aboutTextHeavy}>¡Hablemos y trabajemos juntos!</span><br/><br/>
                </span>
                </div>
                <Footer/>
            </CSSTransitionGroup>
        </div>
    );
};