import React from 'react';

export const Social = () => {
    let socials = [
        {href:'https://www.youtube.com/channel/UCK_fMfqOP24SV8-LdFm7Tow', img:'/img/social/youtube.png'},
        {href:'https://www.tiktok.com/@drk.concept', img:'/img/social/tiktok.png'},
        {href:'https://www.instagram.com/drk.concept/', img:'/img/social/ig.png', target:'_blank'},
        
    ]
    return (
        socials.map((value, index) => {
                return <a href={socials[index].href} key={socials[index].href}><img
                    src={process.env.PUBLIC_URL + socials[index].img}
                    alt={socials[index].img}/></a>
        })
    );
};