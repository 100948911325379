// WhatsAppModal.js
import React from 'react';
import modal_classes from '../css/whatsappModal.module.css';

const WhatsAppModal = ({ show, onHide }) => {
    if (!show) {
        return null;
    }

    return (
        <div className={modal_classes.modalBackdrop} onClick={onHide}>
            <div className={modal_classes.modalContent} onClick={(e) => e.stopPropagation()}>
                <h2>Contactar vía WhatsApp</h2>
                <p>Haz clic en el siguiente enlace para abrir WhatsApp y comenzar una conversación:</p>
                <a 
                    href="https://wa.me/666555444" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className={modal_classes.whatsappLink}
                >
                    Abrir WhatsApp
                </a>
                <button onClick={onHide} style={{marginLeft: '5px'}} className={modal_classes.closeButton}>Cerrar</button>
            </div>
        </div>
    );
};

export default WhatsAppModal;
