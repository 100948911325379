import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CSSTransitionGroup } from 'react-transition-group';
import nav_classes from '../css/mainNavBar.module.css';
import { Social } from './social';
import ReservasModal from './ReservasModal';
import WhatsAppModal from './WhatsAppModal'; // Importar el modal de WhatsApp

export const MainNavBar = () => {
    const [showReservasModal, setShowReservasModal] = useState(false);
    const [showWhatsAppModal, setShowWhatsAppModal] = useState(false);

    const handleReservasClick = () => {
        setShowReservasModal(true);
    };

    const handleCloseReservasModal = () => {
        setShowReservasModal(false);
    };

    const handleWhatsAppClick = () => {
        setShowWhatsAppModal(true);
    };

    const handleCloseWhatsAppModal = () => {
        setShowWhatsAppModal(false);
    };

    return (
        <>
            <CSSTransitionGroup
                transitionName="transition"
                transitionAppear={true}
                transitionAppearTimeout={500}
                transitionEnterTimeout={500}
                transitionLeaveTimeout={300}>
                <div className={nav_classes.navBarWrapper}>
                    <div className={nav_classes.mainLogoWrapper}>
                        <img src={process.env.PUBLIC_URL + '/img/logos/logow.png'} alt=""/>
                    </div>
                    <div className={nav_classes.socialWrapperSmall}>
                        <Social/>
                    </div>
                    <nav className={nav_classes.navBar}>
                        <ul>
                            <li key={0}><NavLink exact to="/"
                                                 className={nav_classes.btn}
                            >INICIO</NavLink></li>

                            <li key={1}><NavLink exact to="/sobremi"
                                                 className={nav_classes.btn}
                                                 activeStyle={{"letterSpacing": "5px", "color": "white"}}
                            >SOBRE MÍ</NavLink></li>

                            <li key={2}><NavLink exact to="/servicios"
                                                 className={nav_classes.btn}
                                                 activeStyle={{"letterSpacing": "5px", "color": "white"}}
                            >SERVICIOS</NavLink></li>

                            <li key={3}><NavLink to="/tarifas"
                                                 className={nav_classes.btn}
                                                 activeStyle={{"letterSpacing": "5px", "color": "white"}}
                            >TARIFAS</NavLink></li>

                            <li key={4}><NavLink to="/contacto"
                                                 className={nav_classes.btn}
                                                 activeStyle={{"letterSpacing": "5px", "color": "white"}}
                            >CONTACTO</NavLink></li>

                            <li key={5}>
                                <button 
                                    className={`${nav_classes.btn} ${nav_classes.reservasButton}`}
                                    onClick={handleReservasClick}
                                >
                                    RESERVAS
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </CSSTransitionGroup>
            <ReservasModal show={showReservasModal} onHide={handleCloseReservasModal} />
            <WhatsAppModal show={showWhatsAppModal} onHide={handleCloseWhatsAppModal} />

            {/* Añadir el icono de WhatsApp */}
            <span 
                onClick={handleWhatsAppClick} 
                className={nav_classes.whatsappLink}
            >
                <img src={process.env.PUBLIC_URL + '/img/logos/whatsapp.png'} alt="WhatsApp" className={nav_classes.whatsappIcon} />
            </span>
        </>
    );
};
