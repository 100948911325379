import React, { Component } from 'react';
import face_classes from '../css/face.module.css';
import classes2 from '../css/producciones_galeria.module.css';
import tarifas_classes from '../css/tarifas.module.css';
import classes from '../css/producciones_galeria.module.css';
import { MainNavBar } from "./mainNavBar";
import { Footer } from "./footer";

class Tarifas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false, // Cambiado a false por defecto
            categoriaSeleccionada: 'fotografia', // Por defecto se muestra la sección de Fotografía
            showModal: false, // Estado para controlar la apertura del modal
            tarifaSeleccionada: null, // Almacenará la tarifa seleccionada para mostrar en el modal
        };
    }

    openModal = (tarifa) => {
        this.setState({ showModal: true, tarifaSeleccionada: tarifa });
    };

    closeModal = () => {
        this.setState({ showModal: false, tarifaSeleccionada: null });
    };

    renderTarifas = () => {
        const { categoriaSeleccionada } = this.state;
        let tarifas = [];

        if (categoriaSeleccionada === 'fotografia') {
            tarifas = [
                {
                    nombre: 'Shooting 2h',
                    precio: '200€',
                    descripcion: 'Que incluye Preproducción: Búsqueda de localizaciones, moodboard, reunión... Formato cinematográfico y/o vertical, incluye también todo el material fotografico y la edición avanzada de 50 imágenes.'
                },
                // Puedes añadir más tarifas aquí si es necesario
            ];
        }

        return (
            <div className={tarifas_classes.tarifasContainer}>
                {tarifas.map((tarifa, index) => (
                    <div key={index} className={tarifas_classes.tarifa} onClick={() => this.openModal(tarifa)}>
                        <h3 className={tarifas_classes.tarifaTitulo}>{tarifa.nombre}</h3>
                        <p className={tarifas_classes.tarifaPrecio}>Precio: {tarifa.precio}</p>
                        <p className={tarifas_classes.tarifaDescripcion}>{tarifa.descripcion}</p>
                        <small className={tarifas_classes.tarifaCat}>Categoría: {categoriaSeleccionada}</small>
                    </div>
                ))}
            </div>
        );
    };

    render() {
        const { showModal, tarifaSeleccionada } = this.state;

        return (
            <div className={face_classes.faceWrapper}>
                <MainNavBar />
                <div>
                    {showModal && tarifaSeleccionada && (
                        <div className={tarifas_classes.modalBackdrop} onClick={this.closeModal}>
                            <div className={tarifas_classes.modalContent} onClick={(e) => e.stopPropagation()}>
                                <button className={classes2.closeButton} onClick={this.closeModal}>X</button>
                                <h2 className={tarifas_classes.modalTitle}>{tarifaSeleccionada.nombre}</h2>
                                <p className={tarifas_classes.modalPrice}>Precio: {tarifaSeleccionada.precio}</p>
                                <p className={tarifas_classes.modalDescription}>{tarifaSeleccionada.descripcion}</p>
                                <small className={tarifas_classes.modalCategory}>Categoría: {this.state.categoriaSeleccionada}</small>
                            </div>
                        </div>
                    )}
                    <div className={tarifas_classes.tarifasWrapper}>
                        <div className={tarifas_classes.contend}>
                            <small className={tarifas_classes.subtitle2}>Aquí puedes encontrar nuestras tarifas actualizadas. ¡Esperamos que encuentres la que mejor se adapte a tus necesidades!</small>
                        </div>
                        <div className={classes.filterBar}>
                            <button className={classes.filterButton} onClick={() => this.setState({ categoriaSeleccionada: 'fotografia' })}>Fotografía</button>
                        </div>
                        {this.renderTarifas()}
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Tarifas;
